export const productDataInitialState = {
  order_information: {
    store_name: "",
    store_id: "",
    franchise_type_id: null,
    id: "",
    ship_to_address: "",
    status_id: 0,
    category_id: "",
    category_name: "",
    order_placement_date: "",
    requested_delivery_date: "",
    commited_delivery_date: "",
    order_confirmation_date: "",
    actual_delivery_date: "",
    description: "",
    short_name: "",
    reviewed_date: "",
    dispatch_date: "",
    payment_confirmation_date: "",
    totalCost: "",
    rateType: null,

    transport_route: "",
    region_id: 0,
    region_name: "",
    logistic_id: "",
    logistic_type: "",
    tracking: [],
    penalty: null,
    payment_status_id: null,

    delivery_receipt: "",
    updated_delivery_receipt: "",
    updated_delivery_goods_receipt: "",
    updated_delivery_region_receipt: "",
    payment_detail_image: "",
    franchisee_payment_detail_image: "",

    remarks: [],
  },
  product_data: [],
};
